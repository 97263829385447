import React, { useEffect, useState } from "react";
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import { API } from "../../config/api";

const CloverPaymentForm = ({ amount, onPaymentSuccess }) => {
  const apiAccessKey = "45d5ffcb597dbff91a6d1ec3ef214721";
  const merchantId = "B8GN3JST3ZVS1";

  const [clover, setClover] = useState(null);
  const [cardElements, setCardElements] = useState({});
  const [errors, setErrors] = useState({});
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const scriptId = "clover-sdk";
    if (!document.getElementById(scriptId)) {
      const script = document.createElement("script");
      script.src = "https://checkout.clover.com/sdk.js";
      script.id = scriptId;
      script.onload = initializeClover;
      document.body.appendChild(script);
    } else {
      initializeClover();
    }
  }, []);

  const initializeClover = () => {
    if (window.Clover) {
      const cloverInstance = new window.Clover(apiAccessKey, { merchantId });
      setClover(cloverInstance);

      const elements = cloverInstance.elements();
      setCardElements({
        cardNumber: elements.create("CARD_NUMBER"),
        cardDate: elements.create("CARD_DATE"),
        cardCvv: elements.create("CARD_CVV"),
        cardPostalCode: elements.create("CARD_POSTAL_CODE"),
      });
    }
  };

  useEffect(() => {
    if (cardElements.cardNumber) {
      cardElements.cardNumber.mount("#card-number");
      cardElements.cardDate.mount("#card-date");
      cardElements.cardCvv.mount("#card-cvv");
      cardElements.cardPostalCode.mount("#card-postal-code");

      Object.keys(cardElements).forEach((key) => {
        cardElements[key].addEventListener("change", (event) => {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [key]: event.error ? event.error.message : "",
          }));
        });
      });
    }
  }, [cardElements]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const result = await clover.createToken();
      if (result.errors) {
        setErrors(result.errors);
        setLoading(false);
        return;
      }

      console.log(amount)
      const token = result.token;
      
      const response = await fetch(`${API}/weekends/charge`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ 
          source: token,
          amount: amount * 100 // Convert to cents
        }),
      });

      const data = await response.json();
      setPaymentResponse(data);

      if (response.ok) {
        onPaymentSuccess();
      } else {
        alert(`Charge failed: ${data.message}`);
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      alert("Payment failed. Please try again.");
    } finally {
  
    }
  };

  return (
    <Box sx={{ p: 3, bgcolor: '#fff', borderRadius: 2, boxShadow: '0 2px 10px rgba(0,0,0,0.05)' }}>
      <form onSubmit={handleSubmit} id="payment-form">
        <Box sx={{ mb: 3 }}>
         <Typography  style={{textAlign:'start'}}><b>
            Card Number</b>
          </Typography>
          <Box 
            sx={{ 
              height: 40, 
              border: '1px solid #ccc', 
              borderRadius: 1, 
              p: 1, 
              bgcolor: 'white',
              '&:hover': { borderColor: '#1976d2' },
            }} 
            id="card-number"
          />
          {errors.cardNumber && (
            <Typography variant="body2" color="error" sx={{ mt: 0.5 }}>
              {errors.cardNumber}
            </Typography>
          )}
        </Box>

        <Box sx={{ mb: 3 }}>
         <Typography  style={{textAlign:'start'}}><b>
            Expiry Date</b>
          </Typography>
          <Box 
            sx={{ 
              height: 40, 
              border: '1px solid #ccc', 
              borderRadius: 1, 
              p: 1, 
              bgcolor: 'white',
              '&:hover': { borderColor: '#1976d2' },
            }} 
            id="card-date"
          />
          {errors.cardDate && (
            <Typography variant="body2" color="error" sx={{ mt: 0.5 }}>
              {errors.cardDate}
            </Typography>
          )}
        </Box>

        <Box sx={{ mb: 3 }}>
         <Typography  style={{textAlign:'start'}}><b>
            CVV</b>
          </Typography>
          <Box 
            sx={{ 
              height: 40, 
              border: '1px solid #ccc', 
              borderRadius: 1, 
              p: 1, 
              bgcolor: 'white',
              '&:hover': { borderColor: '#1976d2' },
            }} 
            id="card-cvv"
          />
          {errors.cardCvv && (
           <Typography  style={{textAlign:'start'}}><b>
              {errors.cardCvv}</b>
            </Typography>
          )}
        </Box>

        <Box sx={{ mb: 3 }}>
        <Typography  style={{textAlign:'start'}}><b>
            Postal Code</b>
          </Typography>
          <Box 
            sx={{ 
              height: 40, 
              border: '1px solid #ccc', 
              borderRadius: 1, 
              p: 1, 
              bgcolor: 'white',
              '&:hover': { borderColor: '#1976d2' },
            }} 
            id="card-postal-code"
          />
          {errors.cardPostalCode && (
            <Typography variant="body2" color="error" sx={{ mt: 0.5 }}>
              {errors.cardPostalCode}
            </Typography>
          )}
        </Box>

        <Button 
          type="submit" 
          variant="contained" 
          color="primary" 
          fullWidth
          disabled={loading}
          sx={{ 
            fontSize: '1.1rem', 
            fontWeight: 600, 
            py: 1.5, 
            borderRadius: 2,
          }}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : `Pay $${amount}`}
        </Button>

        {/* {paymentResponse && (
          <Typography 
            variant="body1" 
            sx={{ mt: 2, textAlign: 'center' }} 
            color={paymentResponse.success ? 'success.main' : 'error'}
          >
            {paymentResponse.message}
          </Typography>
        )} */}
      </form>
    </Box>
  );
};

export default CloverPaymentForm;