import React, { useState, useEffect, useRef } from 'react';
import {
  Box, Typography, Button, TextField, Select, MenuItem, FormControl, InputLabel,
  Card, CardContent, Grid, Divider, Chip, CircularProgress, Modal, Paper, Avatar,
  Switch, FormControlLabel
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { DateTime } from 'luxon';
import { API } from '../config/api';
import CloverPaymentForm from '../components/payment/CheckoutForm';
import uuid from 'react-uuid';
import QRCode from 'react-qr-code';

const theme = createTheme({
  palette: {
    primary: { main: '#1976d2' },
    secondary: { main: '#ff4081' },
    background: { default: '#f5f5f5' },
  },
  typography: {
    h4: { fontWeight: 700 },
    h6: { fontWeight: 600 },
    body1: { fontSize: '1rem' },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          padding: '10px 20px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 8,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          margin: '4px',
        },
      },
    },
  },
});

const attractions = [
  { id: 0, title: 'Golf Simulator', numberOfLanes: 2, pricePerHour: 60, color: 'green' },
  { id: 1, title: 'Axe Throwing', numberOfLanes: 5, pricePerHour: 60, color: '#ff7f50' },
  { id: 2, title: 'Pickleball', numberOfLanes: 1, pricePerHour: 60, color: '#3399ff' },
  { id: 3, title: 'Private Party', numberOfLanes: 0, pricePerHour: 60, color: 'orange' },
];

const timezone = 'America/Chicago';

const BookingForm = () => {
  const [bypassPayment, setBypassPayment] = useState(true);
  const [bookings, setBookings] = useState([]);
  const [selectedAttraction, setSelectedAttraction] = useState(null);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [duration, setDuration] = useState('60');
  const [selectedDate, setSelectedDate] = useState(DateTime.now().setZone(timezone).toISODate());
  const [loading, setLoading] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [receiptOpen, setReceiptOpen] = useState(false);
  const [receiptData, setReceiptData] = useState(null);
  const paymentRef = useRef(null);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const res = await axios.get(`${API}/weekends/bookings`);
        console.log('Fetched bookings:', res.data);
        setBookings(res.data);
      } catch (error) {
        console.error('Error fetching bookings:', error);
      }
    };
    fetchBookings();
  }, []);

  const getAvailableSlots = () => {
    if (!selectedDate || !selectedAttraction || selectedAttraction === '---') {
      console.log('Missing required fields:', { selectedDate, selectedAttraction });
      return [];
    }

    const startOfDay = DateTime.fromISO(selectedDate, { zone: timezone }).set({ hour: 12, minute: 0 });
    if (!startOfDay.isValid) {
      console.log('Invalid date:', selectedDate);
      return [];
    }

    const endOfDay = startOfDay.set({ hour: 23, minute: 0 });
    const durationMinutes = parseInt(duration);
    const slots = [];
    const availableLanes = Array.from({ length: selectedAttraction.numberOfLanes }, (_, i) => i + 1);
    const now = DateTime.now().setZone(timezone);
    const twoHoursFromNow = now.plus({ hours: 2 });

    console.log('Generating slots for:', {
      date: startOfDay.toISODate(),
      attraction: selectedAttraction.title,
      start: startOfDay.toISO(),
      end: endOfDay.toISO(),
      lanes: availableLanes,
      duration: durationMinutes,
      currentTime: now.toISO(),
      cutoffTime: twoHoursFromNow.toISO()
    });

    let currentTime = startOfDay;
    while (currentTime < endOfDay) {
      const slotEnd = currentTime.plus({ minutes: durationMinutes });

      // Skip slots that start within 2 hours of now
      if (currentTime < twoHoursFromNow) {
        currentTime = slotEnd;
        continue;
      }

      const conflictingBookings = bookings.filter(b => {
        const bookingStart = DateTime.fromISO(b.start, { zone: timezone });
        const bookingEnd = DateTime.fromISO(b.end, { zone: timezone });
        return (
          b.title.includes(selectedAttraction.title) &&
          (
            (currentTime >= bookingStart && currentTime < bookingEnd) ||
            (slotEnd > bookingStart && slotEnd <= bookingEnd) ||
            (currentTime <= bookingStart && slotEnd >= bookingEnd)
          )
        );
      });

      const usedLanes = conflictingBookings.map(b => parseInt(b.title.match(/Lane (\d+)/)?.[1] || 0));
      const freeLane = availableLanes.find(lane => !usedLanes.includes(lane));

      if (freeLane || selectedAttraction.numberOfLanes === 0) {
        slots.push({
          start: currentTime.toISO(),
          end: slotEnd.toISO(),
          lane: freeLane || 1
        });
      }

      currentTime = slotEnd;
    }

    console.log('Available slots:', slots);
    return slots;
  };

  const calculateTotalPrice = () => {
    const pricePerSlot = duration === '30' 
      ? selectedAttraction?.pricePerHour / 2 
      : selectedAttraction?.pricePerHour;
    return selectedSlots.length * (pricePerSlot || 0);
  };

  const handleSlotToggle = (slot) => {
    const exists = selectedSlots.some(s => s.start === slot.start);
    if (exists) {
      setSelectedSlots(selectedSlots.filter(s => s.start !== slot.start));
    } else {
      setSelectedSlots([...selectedSlots, slot]);
    }
  };

  const handleContinueToPay = () => {
    if (!name || !email || selectedSlots.length === 0) {
      alert('Please fill all required fields and select at least one time slot');
      return;
    }
    const total = calculateTotalPrice();
    setTotalPrice(total);
    
    if (bypassPayment) {
      handlePaymentSuccess();
    } else {
      setShowPayment(true);
      setTimeout(() => {
        paymentRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  };

  const handlePaymentSuccess = async () => {
    setLoading(true);
    try {
      const bookingPromises = selectedSlots.map(slot => {
        const bookingId = uuid();
        const booking = {
          id: bookingId,
          title: `${selectedAttraction.title} Lane ${slot.lane} - ${name}`,
          start: slot.start,
          end: slot.end,
          description: `Booked by: ${name} (${email})`,
          color: selectedAttraction.color,
          userId: email
        };
        return axios.post(`${API}/weekends/bookings`, booking).then(res => ({
          ...booking,
          id: bookingId,
          _id: res.data.booking._id // Capture the MongoDB _id from the response
        }));
      });

      const results = await Promise.all(bookingPromises);
      console.log('Booking results:', results);

      const total = calculateTotalPrice();
      const receipt = {
        name,
        email,
        attraction: selectedAttraction.title,
        date: selectedDate,
        slots: results.map(r => ({
          id: r.id,
          start: r.start,
          end: r.end,
          lane: r.title.match(/Lane (\d+)/)[1]
        })),
        total
      };

      // Send receipt email and wait for it to complete before showing modal
      await axios.post(`${API}/weekends/send-receipt`, receipt);
      console.log('Receipt email request sent');

      // Only set receipt data and open modal after successful save and email
      setReceiptData(receipt);
      setReceiptOpen(true);

      // Reset form
      setSelectedSlots([]);
      setShowPayment(false);
      setName('');
      setEmail('');
      setBookings([...bookings, ...results]);
    } catch (error) {
      console.error('Error saving bookings:', error);
      alert('Booking failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleReceiptClose = () => {
    setReceiptOpen(false);
    setReceiptData(null);
  };

  const availableSlots = getAvailableSlots();

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ bgcolor: 'background.default' }}>
        <Box
          sx={{
            textAlign: 'center',
            pt: 6,
            pb: 6,
            position: 'relative',
            backgroundImage: `url(${require('../assets/images/booking/booking-background.jpeg')})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            overflow: 'hidden',
            color: '#fff',
          }}
        >
          {/* Overlay for the dark background effect */}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              bgcolor: 'rgba(0, 0, 0, 0.6)', // Dark overlay
              zIndex: 1, // Ensure it's behind the content
            }}
          />

          {/* Content Box */}
          <Box sx={{ position: 'relative', zIndex: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Avatar
                src={require('../assets/images/logo/logo-badge.png')}
                alt="Logo"
                sx={{ width: 100, height: 100 }}
              />
            </Box>
            <Typography variant="h4" color="white" gutterBottom>
              Book Your Weekend Adventure
            </Typography>
            <Typography sx={{ maxWidth: 800, mx: 'auto', color: 'white' }}>
              Reserve your spot for an exciting experience! Select your date, attraction, and time slots below.
              Please arrive 10 minutes early and check in at the front desk with your QR code or Reservation Name.
              A confirmation email with your receipt and QR code will be sent upon booking.
            </Typography>
            <Divider sx={{ my: 3, maxWidth: 200, mx: 'auto', borderColor: '#fff', opacity: 0.6 }} />
            <Typography color="white" variant="body1">
              <strong>Hours:</strong> 12 PM - 11 PM | <strong>Pricing:</strong> $30 for 30 min, $60 for 1 hr
            </Typography>
          </Box>
        </Box>

        <Card>
          <CardContent sx={{ p: 4 }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Booking Details
                </Typography>
                <Typography style={{textAlign:'start'}}><b>Select Date</b></Typography>
                <TextField
                  fullWidth
                  type="date"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                  sx={{ mb: 3, textAlign:'start' }}
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                />
                <FormControl fullWidth sx={{ mb: 3 }}>
                  <Typography style={{textAlign:'start'}}><b>Attraction</b></Typography>
                  <Select
                    style={{textAlign:'start'}}
                    value={selectedAttraction?.id ?? '---'}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSelectedAttraction(value === '---' ? null : attractions.find(attr => attr.id === value));
                    }}
                    variant="outlined"
                  >
                    <MenuItem value="---">---</MenuItem>
                    {attractions.map((attr) => (
                      <MenuItem key={attr.id} value={attr.id}>
                        {attr.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ mb: 3 }}>
                  <Typography style={{textAlign:'start'}}><b>Duration</b></Typography>
                  <Select
                    style={{textAlign:'start'}}
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                    variant="outlined"
                  >
                    <MenuItem value="30">30 Minutes</MenuItem>
                    <MenuItem value="60">1 Hour</MenuItem>
                  </Select>
                </FormControl>
                <Typography style={{textAlign:'start'}}><b>Reservation Name:</b></Typography>
                <TextField
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  sx={{ mb: 3 }}
                  variant="outlined"
                />
                <Typography style={{textAlign:'start'}}><b>Email</b></Typography>
                <TextField
                  fullWidth
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{ mb: 3 }}
                  variant="outlined"
                />
                <Box sx={{ mb: 3 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={bypassPayment}
                        onChange={(e) => setBypassPayment(e.target.checked)}
                        color="primary"
                      />
                    }
                    label="Bypass Payment (Testing)"
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="h6" color="primary" gutterBottom>
                  Available Time Slots
                </Typography>
                <Box sx={{ maxHeight: 300, overflowY: 'auto', bgcolor: '#fff', p: 2, borderRadius: 2, boxShadow: 1 }}>
                  {availableSlots.length > 0 ? (
                    availableSlots.map((slot, index) => (
                      <Chip
                        key={index}
                        label={`${DateTime.fromISO(slot.start).toLocaleString(DateTime.TIME_SIMPLE)} - ${DateTime.fromISO(slot.end).toLocaleString(DateTime.TIME_SIMPLE)}`}
                        onClick={() => handleSlotToggle(slot)}
                        color={selectedSlots.some(s => s.start === slot.start) ? 'primary' : 'default'}
                        clickable
                        sx={{ fontSize: '0.9rem', py: 2 }}
                      />
                    ))
                  ) : (
                    <Typography color="text.secondary">
                      {selectedDate && selectedAttraction && selectedAttraction !== '---' 
                        ? 'No available slots for this selection' 
                        : 'Select a date and attraction to see available slots'}
                    </Typography>
                  )}
                </Box>
                <Typography variant='caption'>Note: You can select multiple times.</Typography>

                <Box sx={{ mt: 3 }}>
                  <Typography variant="h6" color="text.primary">
                    Selected Slots: {selectedSlots.length}
                  </Typography>
                  <Typography variant="h6" color="text.primary">
                    Total Price: ${calculateTotalPrice()}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleContinueToPay}
                    disabled={selectedSlots.length === 0 || loading}
                    sx={{ mt: 2, fontSize: '1.1rem', fontWeight: 600 }}
                  >
                    {loading ? <CircularProgress size={24} color="inherit" /> : 
                      bypassPayment ? 'Book Now' : 'Continue to Pay'}
                  </Button>
                </Box>
              </Grid>
            </Grid>

            {showPayment && !bypassPayment && (
              <Box ref={paymentRef} sx={{ mt: 4 }}>
                <Divider sx={{ mb: 2, borderColor: 'primary.main' }} />
                <Typography variant="h5" gutterBottom align="center" color="primary">
                  Complete Your Payment
                </Typography>
                <CloverPaymentForm 
                  amount={totalPrice}
                  onPaymentSuccess={handlePaymentSuccess}
                />
              </Box>
            )}
          </CardContent>
        </Card>

        <Modal open={receiptOpen} onClose={handleReceiptClose}>
          <Paper sx={{ 
            position: 'absolute', 
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)', 
            p: 4, 
            maxWidth: 500, 
            width: '90%', 
            borderRadius: 3,
            boxShadow: '0 8px 32px rgba(0,0,0,0.2)',
          }}>
            <Typography variant="h5" gutterBottom align="center" color="primary">
              Thank You for Booking!
            </Typography>
            <Typography variant="body1" gutterBottom>
              Hello {receiptData?.name}, thank you for your booking with us! Please arrive 10 minutes before your scheduled time. Check-in at the front desk with your QR Code or reservation name. A receipt has been sent to {receiptData?.email}.
              This QR code has been emailed to you with your reservation details. Please check your spam if you don’t see it in your inbox.
            </Typography>
            <Typography variant="body1" gutterBottom>
              You can also <b>screenshot</b> this and present the QR code when you arrive. We look forward to seeing you!
            </Typography>
            <Divider sx={{ my: 2, borderColor: 'primary.main' }} />
            <Typography variant="h6">Booking Details:</Typography>
            <Typography variant="body1">Attraction: {receiptData?.attraction}</Typography>
            <Typography variant="body1">Date: {receiptData?.date}</Typography>
            <Typography variant="body1">Slots Booked:</Typography>
            {receiptData?.slots.map((slot, index) => (
              <Typography key={index} variant="body2" sx={{ ml: 2 }}>
                - Lane {slot.lane}: {DateTime.fromISO(slot.start).toLocaleString(DateTime.TIME_SIMPLE)} - {DateTime.fromISO(slot.end).toLocaleString(DateTime.TIME_SIMPLE)}
              </Typography>
            ))}
            <Typography variant="body1" sx={{ mt: 1 }}>Total Cost: ${receiptData?.total}</Typography>
            <Divider sx={{ my: 2, borderColor: 'primary.main' }} />
            <Typography variant="h6" align="center">Booking ID QR Code:</Typography>
            {receiptData?.slots && receiptData.slots.length === 1 && receiptData.slots[0].id ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <QRCode value={receiptData.slots[0].id} size={150} />
              </Box>
            ) : (
              <Typography variant="body2" align="center">
                {receiptData?.slots && receiptData.slots.length > 1 
                  ? `Multiple bookings made. First ID: ${receiptData.slots[0].id}`
                  : 'QR Code unavailable'}
              </Typography>
            )}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleReceiptClose}
              sx={{ mt: 2, fontSize: '1rem' }}
            >
              Close
            </Button>
          </Paper>
        </Modal>
      </Box>
    </ThemeProvider>
  );
};

export default BookingForm;