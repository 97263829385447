import { Route, Routes } from "react-router-dom";
import "./App.css";
import "./styles/all.css";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.js"
import "./styles/custom.css";
import "./styles/media-query.css";
import "../node_modules/magnific-popup/dist/jquery.magnific-popup.js"
import 'magnific-popup/dist/magnific-popup.css';
import Home from "./components/Home";
import Chef from "./components/chef/Chef.jsx";
import Blog from "./components/blog/Blog.jsx";
import Login from './screens/auth/Login.jsx'
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Layout from "./screens/Layout.jsx";
import { useEffect, useState } from "react";
import PrivateRoute from "./navigation/ProtectedRoute.js";
import BookingForm from "./screens/BookingForm.jsx";

function App() {

  const [user, setUser] = useState(null);

  useEffect(() => {
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
          setUser(JSON.parse(storedUser));
      }
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login onLogin={setUser} />} />
                <Route 
                    path="/dashboard" 
                    element={
                        <PrivateRoute>
                            <Layout />
                        </PrivateRoute>
                    } 
                />
       <Route path="*" element={<Login onLogin={setUser} />} />
       <Route path="/booking" element={<BookingForm/>}/>
      </Routes>
    </div>
    </LocalizationProvider>
  );
}

export default App;
