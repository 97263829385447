import React, { useState } from 'react';
import { 
    Box, CssBaseline, AppBar, Toolbar, Drawer, List, 
    ListItemIcon, ListItemText, Typography, IconButton, 
    Menu, MenuItem, ListItemButton, useMediaQuery,
    Divider
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CalendarMonth, AccountCircle } from '@mui/icons-material';
import WCalendar from './WCalendar';
import Dashboard from './Dashboard';
import AccountSettings from './AccountSettings';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo/logo-badge.png'
const drawerWidth = 240;
const collapsedWidth = 60;
const theme = createTheme();

const Layout = () => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [view, setView] = useState(<Dashboard />);
    const [title, setTitle] = useState('Dashboard')
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [desktopOpen, setDesktopOpen] = useState(true);
    const isMobile = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate();

    // ✅ Toggle Drawer
    const handleDrawerToggle = () => {
        if (isMobile) {
            setMobileOpen(!mobileOpen);
        } else {
            setDesktopOpen(!desktopOpen);
        }
    };

    // ✅ Open & Close User Menu
    const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    // ✅ Logout
    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/login');
    };

    // ✅ Drawer Content
    const drawer = (
        <Box sx={{ width: drawerWidth }}>
            {desktopOpen === false && (
            <Toolbar />
            )}
            <List>
                <ListItemButton onClick={() => {setView(<Dashboard />); setTitle('Dashboard')}}>
                    <ListItemIcon><DashboardIcon /></ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItemButton>
                <ListItemButton onClick={() => {setView(<WCalendar />); setTitle('Calendar')}}>
                    <ListItemIcon><CalendarMonth /></ListItemIcon>
                    <ListItemText primary="Calendar" />
                </ListItemButton>
            </List>
        </Box>
    );

    return (
        <ThemeProvider theme={theme}>
            <Box 
                sx={{ 
                    display: 'flex', 
                    width: '100vw', // ✅ Full width
                    overflowX: 'hidden' // ✅ Prevent horizontal scrolling
                }}
            >
                <CssBaseline />

                {/* ✅ AppBar */}
                <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle} sx={{ mr: 2 }}>
                            <MenuIcon />
                        </IconButton>
                        <Typography color='white' variant="h6" noWrap sx={{ flexGrow: 1 }}>
                            {title}
                        </Typography>

                        {/* ✅ User Menu */}
                        <IconButton color="inherit" onClick={handleMenuOpen}>
                            <AccountCircle />
                        </IconButton>
                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                            <MenuItem onClick={() => { setView(<AccountSettings />); setTitle('Settings'); handleMenuClose(); }}>
                                <SettingsIcon sx={{ mr: 1 }} /> Settings
                            </MenuItem>
                            <MenuItem onClick={handleLogout}>
                                <LogoutIcon sx={{ mr: 1 }} /> Logout
                            </MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>

                {/* ✅ Mobile Drawer (Temporary) */}
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { width: drawerWidth }
                    }}
                >
                       <Toolbar/>
                    <img src={logo} height="auto" width={50}/>
                    <Divider sx={{height:2}}/>
                 
                    {drawer}
                </Drawer>

                {/* ✅ Desktop Drawer (Collapsible) */}
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        transition: 'width 0.3s',
                        '& .MuiDrawer-paper': {
                            width: desktopOpen ? drawerWidth : collapsedWidth,
                            overflowX: 'hidden',
                        }
                    }}
                    open={desktopOpen}
                >
                    {desktopOpen === true && (
                        <>
                        <Box sx={{pt:10}}>
                        <img src={logo} height="auto" width={175}/>
                        </Box>
                        <Divider sx={{height:2}}/>
                        </>
                    )}
                   
                    {drawer}
                </Drawer>

                {/* ✅ Main Content - Adjusted for Small Screens */}
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        p: 3,
                        width: "100%", // ✅ Full width
                        minHeight: "100vh", // ✅ Full viewport height
                        transition: 'margin 0.3s',
                        ml: isMobile ? 0 : desktopOpen ? `${drawerWidth}px` : `${collapsedWidth}px`,
                        overflowX: 'hidden' // ✅ Prevent horizontal scrolling
                    }}
                >
                    <Box textAlign='left'>
                        <Typography color='black' variant='button' marginTop={8} fontSize={16}>
                            Welcome, {user?.name?.split(' ')[0]}!
                        </Typography>
                    </Box>
                    {view}
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default Layout;
