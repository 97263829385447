import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../../assets/images/logo/logo-badge.png';
import { API } from '../../config/api';

const theme = createTheme();

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    // ✅ Handle Login
    const handleLogin = async (e) => {
        e.preventDefault(); // Prevent form submission

        try {
            const res = await axios.post(`${API}/weekends/users/login`, { email, password });

            // ✅ Store the token and user info in localStorage
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('user', JSON.stringify(res.data.user));

            // ✅ Redirect to Dashboard
            navigate('/dashboard');
        } catch (err) {
            setError(err.response?.data?.error || 'Login failed. Please try again.');
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs" sx={{ mt: 8 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {/* Logo Section */}
                    <img src={logo} width={200} height="auto" alt="Logo" />

                    <Typography color='black' fontSize={20} variant="button">
                        Sign In
                    </Typography>

                    {/* Error Message */}
                    {error && (
                        <Typography color="error" sx={{ mt: 2 }}>
                            {error}
                        </Typography>
                    )}

                    {/* Login Form */}
                    <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={handleLogin}>
                        {/* Email Field */}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />

                        {/* Password Field */}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />

                        {/* Submit Button */}
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                            Sign In
                        </Button>

                        {/* Links */}
                        {/* <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                            <a href="#" style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
                                Forgot password?
                            </a>
                        </Typography>
                        <Typography variant="body2" align="center" sx={{ mt: 1 }}>
                            Don't have an account?{' '}
                            <a href="#" style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
                                Sign Up
                            </a>
                        </Typography> */}
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default Login;
