import React, { useEffect, useState } from 'react';
import { 
    Box, Table, TableBody, TableCell, TableContainer, 
    TableHead, TableRow, Paper, Button, Modal, Backdrop, Fade, 
    Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    IconButton, Select, MenuItem, FormControl, InputLabel, CircularProgress
} from '@mui/material';
import axios from 'axios';
import { DateTime } from 'luxon';
import { Delete, Info } from '@mui/icons-material';
import { API } from '../config/api';

const timezone = 'America/Chicago';

// Function to format date & time in 12-hour format
const formatDateTime = (isoString) => {
    const date = DateTime.fromISO(isoString, { zone: 'utc' }).setZone(timezone);
    return date.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS); // Example: "Feb 8, 2025, 1:00 PM"
};

const formatDuration = (start, end) => {
    const startTime = DateTime.fromISO(start, { zone: "utc" }).setZone(timezone);
    const endTime = DateTime.fromISO(end, { zone: "utc" }).setZone(timezone);
    const diff = endTime.diff(startTime, "minutes").minutes;
    return `${diff} minutes`;
  };

// Capitalize status for display
const formatStatus = (status) => {
    return status 
        ? status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()
        : "Pending";
};

const Dashboard = () => {
    const [bookings, setBookings] = useState([]);
    const [selectedBooking, setSelectedBooking] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [bookingToDelete, setBookingToDelete] = useState(null);
    const [tempStatus, setTempStatus] = useState(''); // Track temporary status change
    const [isUpdating, setIsUpdating] = useState(false); // Loading state for update
    const [updateSuccess, setUpdateSuccess] = useState(false); // Confirmation state

    // Fetch today's bookings
    const getTodaysBookings = async () => {
        try {
            const today = DateTime.now().setZone(timezone).startOf('day').toISO();
            const tomorrow = DateTime.now().setZone(timezone).plus({ days: 1 }).startOf('day').toISO();

            const res = await axios.get(`${API}/weekends/bookings`);
            const todaysBookings = res.data.filter(booking => 
                booking.start >= today && booking.start < tomorrow
            );

            setBookings(todaysBookings);
        } catch (error) {
            console.error("Error fetching today's bookings:", error);
        }
    };

    useEffect(() => {
        getTodaysBookings();
    }, []);

    // Handle clicking a booking to view details
    const handleViewDetails = (booking) => {
        setSelectedBooking(booking);
        setTempStatus(booking.status || 'pending'); // Initialize tempStatus
        setUpdateSuccess(false); // Reset success message
        setOpenModal(true);
    };

    // Handle modal close
    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedBooking(null);
        setTempStatus('');
        setUpdateSuccess(false);
    };

    // Handle delete button click (opens confirmation dialog)
    const handleDeleteClick = (booking) => {
        setBookingToDelete(booking);
        setOpenConfirmDialog(true);
    };

    // Handle confirming delete
    const handleConfirmDelete = async () => {
        try {
            await axios.delete(`${API}/weekends/bookings/${bookingToDelete._id}`);
            setBookings(bookings.filter(b => b._id !== bookingToDelete._id));
            setOpenConfirmDialog(false);
            setBookingToDelete(null);
        } catch (error) {
            console.error("Error deleting booking:", error);
        }
    };

    // Handle status change in dropdown
    const handleStatusChange = (event) => {
        setTempStatus(event.target.value);
        setUpdateSuccess(false); // Reset success message when status changes
    };

    // Handle update button click
    const handleUpdateStatus = async () => {
        if (!selectedBooking || tempStatus === selectedBooking.status) return;

        setIsUpdating(true);
        try {
            const response = await axios.post(`${API}/weekends/change-reservation-status`, {
                bookingId: selectedBooking.id, // Use custom 'id' field, not '_id'
                status: tempStatus
            });
            
            const updatedBooking = response.data.booking;
            // Update local state
            setBookings(bookings.map(b => 
                b._id === selectedBooking._id ? updatedBooking : b
            ));
            setSelectedBooking(updatedBooking);
            setUpdateSuccess(true); // Show confirmation
        } catch (error) {
            console.error("Error updating booking status:", error.response?.data || error);
            alert(error.response?.data?.error || 'Failed to update status. Please try again.');
        } finally {
            setIsUpdating(false);
        }
    };

    return (
        <Box sx={{ p: 0 }}>
            <Typography color='black' variant="button" sx={{ mb: 2, mt: 2, fontSize: 20 }}>
                Today's Bookings
            </Typography>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Title</strong></TableCell>
                            <TableCell><strong>Start Time</strong></TableCell>
                            <TableCell><strong>Duration</strong></TableCell>
                            <TableCell><strong>Status</strong></TableCell>
                            <TableCell><strong>Actions</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bookings.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={5} align="center">No bookings for today.</TableCell>
                            </TableRow>
                        ) : (
                            bookings.map((booking) => {
                                const status = formatStatus(booking.status);
                                const statusColor = status === 'Complete' ? 'green' : status === 'Pending' ? 'orange' : 'tomato';

                                return (
                                    <TableRow key={booking._id}>
                                        <TableCell>{booking.title}</TableCell>
                                        <TableCell>{formatDateTime(booking.start)}</TableCell>
                                        <TableCell>{formatDuration(booking.start, booking.end)}</TableCell>
                                        <TableCell>
                                            <Typography sx={{ color: statusColor, fontWeight: 'bold' }}>
                                                {status}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton 
                                                onClick={() => handleViewDetails(booking)}
                                                sx={{ mr: 1 }}
                                            >
                                                <Info htmlColor='dodgerBlue'/>
                                            </IconButton>
                                            <IconButton 
                                                onClick={() => handleDeleteClick(booking)}
                                            >
                                                <Delete htmlColor='tomato'/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Booking Details Modal */}
            <Modal 
                open={openModal} 
                onClose={handleCloseModal} 
                closeAfterTransition 
                BackdropComponent={Backdrop} 
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={openModal}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: { xs: '90%', md: 600 },
                        bgcolor: 'background.paper',
                        p: 4,
                        boxShadow: 24,
                        borderRadius: 2,
                    }}>
                        {selectedBooking && (
                            <>
                                <Typography variant="h6">{selectedBooking.title}</Typography>
                                <Typography sx={{ mt: 2 }}>
                                    <strong>Start:</strong> {formatDateTime(selectedBooking.start)}
                                </Typography>
                                <Typography sx={{ mt: 1 }}>
                                    <strong>End:</strong> {formatDateTime(selectedBooking.end)}
                                </Typography>
                                <Typography sx={{ mt: 1 }}>
                                    <strong>Description:</strong> {selectedBooking.description || 'No description'}
                                </Typography>
                                <FormControl fullWidth sx={{ mt: 2 }}>
                                    <InputLabel>Status</InputLabel>
                                    <Select
                                        value={tempStatus}
                                        label="Status"
                                        onChange={handleStatusChange}
                                    >
                                        <MenuItem value="pending">Pending</MenuItem>
                                        <MenuItem value="complete">Complete</MenuItem>
                                        <MenuItem value="canceled">Canceled</MenuItem>
                                        <MenuItem value="no-show">No-Show</MenuItem>
                                    </Select>
                                </FormControl>
                                {updateSuccess && (
                                    <Typography sx={{ mt: 2, color: 'green' }}>
                                        Status updated successfully!
                                    </Typography>
                                )}
                                <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                                    <Button 
                                        onClick={handleCloseModal} 
                                        variant="outlined"
                                        disabled={isUpdating}
                                    >
                                        Close
                                    </Button>
                                    {tempStatus !== (selectedBooking.status || 'pending') && (
                                        <Button 
                                            onClick={handleUpdateStatus} 
                                            variant="contained"
                                            color="primary"
                                            disabled={isUpdating}
                                            startIcon={isUpdating ? <CircularProgress size={20} /> : null}
                                        >
                                            {isUpdating ? 'Updating...' : 'Update Status'}
                                        </Button>
                                    )}
                                </Box>
                            </>
                        )}
                    </Box>
                </Fade>
            </Modal>

            {/* Delete Confirmation Dialog */}
            <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this booking? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmDialog(false)} color="primary">Cancel</Button>
                    <Button onClick={handleConfirmDelete} color="error" variant="contained">Delete</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Dashboard;