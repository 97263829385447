import React, { useEffect, useState } from 'react';
import { 
    Box, CssBaseline, AppBar, Toolbar, Drawer, List, 
    ListItem, ListItemIcon, ListItemText, Typography, 
    IconButton, Modal, Backdrop, Fade, Button, TextField, 
    MenuItem, Select, FormControl, InputLabel,
    Divider
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import axios from 'axios';
import { DateTime } from 'luxon';
import { Add, CalendarMonth } from '@mui/icons-material';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { API } from '../config/api';

const timezone = 'America/Chicago';
const drawerWidth = 240;
const theme = createTheme();
const localizer = momentLocalizer(moment);

const formatDateTime = (isoString) => {
    const date = new Date(isoString);

    // Extract parts of the date
    const options = { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric', 
        hour: 'numeric', 
        minute: '2-digit', 
        hour12: true, // Enables AM/PM format
        timeZone: 'America/Chicago' // Set the desired timezone
    };

    return date.toLocaleString('en-US', options);
};


// Map booking types to colors
const bookingColors = {
    "Axe Throwing": "#ff7f50",  // Coral
    "Golf Simulators": "green", // Green
    "Pickleball": "blue"        // Blue
};

const attractions = [
   {
    id:0,
    title: 'Golf Simulator',
    numberOfLanes:2,
    color:"green"
   } ,
   {
    id:1,
    title: 'Axe Throwing',
    numberOfLanes:5,
    color:"#ff7f50"
   },
   {
    id:2,
    title: 'Pickleball',
    numberOfLanes:1,
    color:"#3399ff"
   },
   {
    id:3,
    title: 'Private Party',
    numberOfLanes:0,
    color:"orange"
   }
]

// Convert raw event data for the calendar
const parseEvents = (events) => {
    return events.map(event => {
        const start = DateTime.fromISO(event.start, { zone: 'utc' }).setZone(timezone).toJSDate();
        const end = DateTime.fromISO(event.end, { zone: 'utc' }).setZone(timezone).toJSDate();

        return {
            ...event,
            start,
            end
        };
    });
};

// Event styling
const eventStyleGetter = (event) => ({
    style: {
        backgroundColor: event.color || '#3174ad', // Default Blue
        borderRadius: '5px',
        opacity: 0.8,
        color: 'white',
        border: '0px',
        display: 'block',
    }
});

const WCalendar = () => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')))
    const [open, setOpen] = useState(false);
    const [formOpen, setFormOpen] = useState(false);
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [attraction, setAttraction] = useState(null)
    const [title, setTitle] = useState('')
    const [lane, setLane] = useState(0)
    const [newBooking, setNewBooking] = useState({
        title: '',
        start: '',
        end: '',
        description: '',
        type: '',
        duration: '30' // Default to 30 minutes
    });

    // Fetch bookings from API
    const getBookings = async () => {
        try {
            const res = await axios.get(`${API}/weekends/bookings`);
            const parsedEvents = parseEvents(res.data);
            setEvents(parsedEvents);
            setLoading(false)
        } catch (error) {
            console.error('Error fetching bookings:', error);
        }
    };

    // Calculate the end time based on the selected duration
    const calculateEndTime = (start, duration) => {
        if (!start) return '';
        const startTime = DateTime.fromISO(start, { zone: timezone });
        return startTime.plus({ minutes: parseInt(duration) }).toISO();
    };

    // Handle changes in the booking form
    const handleBookingChange = (field, value) => {
        let updatedBooking = { ...newBooking, [field]: value };

        // If the start time or duration changes, update the end time
        if (field === 'start' || field === 'duration') {
            updatedBooking.end = calculateEndTime(updatedBooking.start, updatedBooking.duration);
        }

        setNewBooking(updatedBooking);
    };

    // Add new booking to DB
    const addBooking = async () => {
        try {
            const color = bookingColors[newBooking.type] || "#3174ad"; // Default color if not found

            const newEvent = {
                ...newBooking,
                title: `${attraction.title} Lane ${lane} - ${title}`,
                color: attraction.color,
                start: new Date(newBooking.start).toISOString(),
                end: new Date(newBooking.end).toISOString()
            };
            console.log(newEvent)
            const res = await axios.post(`${API}/weekends/bookings`, newEvent);
            setEvents([...events, { ...res.data.booking, start: new Date(res.data.booking.start), end: new Date(res.data.booking.end) }]);

            setFormOpen(false);
            setNewBooking({ title: '', start: '', end: '', description: '', type: '', duration: '30' });
        } catch (error) {
            console.error('Error adding booking:', error);
        }
    };

    const handleDeleteBooking = async () => {
        if (!selectedEvent) return;
    
        const confirmDelete = window.confirm(`Are you sure you want to delete this booking: "${selectedEvent.title}"?`);
        if (!confirmDelete) return;
    
        try {
            await axios.delete(`${API}/weekends/bookings/${selectedEvent._id}`);
    
            // Remove the event from state
            setEvents(events.filter(event => event._id !== selectedEvent._id));
    
            setOpen(false); // Close the modal after deleting
            setSelectedEvent(null);
        } catch (error) {
            console.error("Error deleting booking:", error);
        }
    };
    

    useEffect(() => {
        getBookings();
    }, []);

    const handleEventClick = (event) => {
        console.log("🟢 Event Clicked:", event); // ✅ Check if it's being triggered
        setSelectedEvent(event);
        setOpen(true);
    };
    

    const handleClose = () => {
        setOpen(false);
        setSelectedEvent(null);
    };
if(loading){
    return(<p>Loading</p>)
} else {
    
    return (

<>
                {/* Main Content */}
                <Box textAlign="end">
                    <Button  variant="contained" color="primary" onClick={() => setFormOpen(true)}>
                      <Add/>  Add Booking
                    </Button>
                    </Box>

                    <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500, marginTop: 20 }}
                    onSelectEvent={handleEventClick}  // ✅ This ensures event clicks work
                    eventPropGetter={eventStyleGetter}
                />


       

            {/* Booking Form Modal */}
            <Modal open={formOpen} onClose={() => setFormOpen(false)} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
                <Fade in={formOpen}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: {xs: '100%', md:600},
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}>
                     <Typography fontSize={20} variant='button'>Add Booking</Typography>

                        <FormControl fullWidth sx={{ mb: 2 }}>
                        <Typography variant='button'>Reservation Name</Typography>

                        <TextField fullWidth value={title} onChange={(e) => setTitle(e.target.value)} sx={{ mb: 2 }} />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                        <Typography variant='button'>Date and Time</Typography>

                        <TextField fullWidth type="datetime-local" onChange={(e) => handleBookingChange('start', e.target.value)} sx={{ mb: 2 }} />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                
                            <Typography variant='button'>Duration</Typography>
                            <Select value={newBooking.duration} onChange={(e) => handleBookingChange('duration', e.target.value)}>
                                <MenuItem value="30">30 Minutes</MenuItem>
                                <MenuItem value="60">1 Hour</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                        <Typography variant='button'>Attraction</Typography>
                            <Select onChange={(e) => setAttraction(attractions[e.target.value])}>
                                <MenuItem value="---">---</MenuItem>
                                 {attractions.map((i,index)=>{
                                    return <MenuItem value={index}>{i.title}</MenuItem>
                                 })}
                            </Select>
                        </FormControl>

                        {attraction !== null && attraction?.title !== 'Private Party' && (
                        <FormControl fullWidth sx={{ mb: 2 }}>
                        <Typography variant='button'>Select Lane</Typography>
                            <Select onChange={(e) => setLane(e.target.value)}>
                            {Array.from({ length: attraction?.numberOfLanes || 0 }, (_, index) => (
                                <MenuItem key={index + 1} value={index + 1}>
                                    {index + 1}
                                </MenuItem>
                            ))}

                            </Select>
                        </FormControl>
                        )}
                       

                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <Typography variant='button'>Notes</Typography>
                            <TextField fullWidth multiline minRows={4} onChange={(e) => handleBookingChange('description', `${e.target.value} <br/> <p>Added by: ${user.name}`)} />
                        </FormControl>
                        <Button variant="contained" onClick={addBooking}>Save</Button>
                        <Button sx={{float:'inline-end'}} variant="contained" color='info' onClick={() => setFormOpen(false)}>Close</Button>
                    </Box>
                </Fade>
            </Modal>

            <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            >
            <Fade in={open}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: {xs: '90%', md:600},
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    {selectedEvent && (
                        <Box sx={{ color: 'black' }}>
                            <Typography color="black" sx={{ mt: 2 }} variant="h6" component="h2">
                                Title:
                            </Typography>
                            <Typography variant="h6" component="h2" color="black">
                                {selectedEvent.title}
                            </Typography>
                            <Divider sx={{ height: 2 }} />
                            <Typography color="black" sx={{ mt: 2 }}>
                                Start:
                            </Typography>
                            <Typography color="black">
                                {formatDateTime(selectedEvent.start)}
                            </Typography>
                            <Divider sx={{ height: 2 }} />
                            <Typography color="black" sx={{ mt: 1 }}>
                                End:
                            </Typography>
                            <Typography color="black">
                                {formatDateTime(selectedEvent.end)}
                            </Typography>
                            <Divider sx={{ height: 2 }} />
                            <Typography color="black" sx={{ mt: 1 }}>
                                Notes:
                            </Typography>
                            <Typography color="black">
                              <div dangerouslySetInnerHTML={{__html:selectedEvent.description}}/>
                            </Typography>

                            {/* Buttons: Close & Delete */}
                            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                                <Button onClick={handleClose} variant="contained" color="primary">
                                    Close
                                </Button>
                                <Button onClick={handleDeleteBooking} variant="contained" color="error">
                                    Delete
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Fade>
        </Modal>

            </>
    );

}
};


export default WCalendar;
