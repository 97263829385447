import React, { useEffect, useState } from 'react';
import { 
    Box, Table, TableBody, TableCell, TableContainer, 
    TableHead, TableRow, Paper, Button, Modal, Fade, 
    Backdrop, TextField, MenuItem, Select, FormControl, 
    InputLabel, Typography
} from '@mui/material';
import axios from 'axios';
import { Add } from '@mui/icons-material';
import { API } from '../config/api';

const AccountSettings = () => {
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('user')))
    const [users, setUsers] = useState([]);
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [newUser, setNewUser] = useState({ name: '', email: '', password: '', role: '' });

    // ✅ Fetch users from API
    const getUsers = async () => {
        try {
            const res = await axios.get(`${API}/weekends/users`);
            setUsers(res.data);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    useEffect(() => {
        getUsers();
    }, []);

    // ✅ Handle input changes
    const handleChange = (field, value) => {
        setNewUser(prev => ({ ...prev, [field]: value }));
    };

    // ✅ Add a new user
    const addUser = async () => {
        setNewUser({ name: '', email: '', password: '', role: '' });
        try {
            await axios.post(`${API}/weekends/users`, newUser);
            getUsers();
           
            setOpen(false);
           
        } catch (error) {
            console.error("Error adding user:", error);
        }
    };

    // ✅ Open edit modal and pre-fill data (excluding password)
    const openEditModal = (user) => {
        setSelectedUser(user);
        setNewUser({ name: user.name, email: user.email, role: user.role, password: '' }); // Do not prefill password
        setEditOpen(true);
    };

    // ✅ Update user (excluding empty password fields)
    const updateUser = async () => {
        try {
            const updateData = { ...newUser };
            if (!newUser.password) {
                delete updateData.password; // Do not send empty password field
            }

            await axios.put(`${API}/weekends/users/${selectedUser._id}`, updateData);
            getUsers();
            setEditOpen(false);
            setSelectedUser(null);
        } catch (error) {
            console.error("Error updating user:", error);
        }
    };

    // ✅ Delete user
    const deleteUser = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this user?");
        if (!confirmDelete) return;

        try {
            await axios.delete(`${API}/weekends/users/${id}`);
            getUsers();
        } catch (error) {
            console.error("Error deleting user:", error);
        }
    };

    return (
        <Box >
       
            
          
            <Box textAlign="start">
            <Typography variant='button' fontSize={20}>Users</Typography>
            {userData.role === 'admin' && (
            <Button sx={{float:'inline-end'}} variant="contained" color="primary" onClick={() => setOpen(true)}>
               <Add/> Add User
            </Button>
            )}
            </Box>

            {/* ✅ Users Table */}
            <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Name</strong></TableCell>
                            <TableCell><strong>Email</strong></TableCell>
                            <TableCell><strong>Role</strong></TableCell>
                            <TableCell><strong>Actions</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={4} align="center">No users found.</TableCell>
                            </TableRow>
                        ) : (
                            users.map(user => (
                                <TableRow key={user._id}>
                                    <TableCell>{user.name}</TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>{user.role.toUpperCase()}</TableCell>
                                    <TableCell>
                                        {userData.role === 'admin' && (
                                            <>
                                            <Button variant="outlined" color="primary" onClick={() => openEditModal(user)}>
                                            Edit
                                        </Button>
                                        <Button variant="outlined" color="error" onClick={() => deleteUser(user._id)} sx={{ ml: 1 }}>
                                            Delete
                                        </Button>
                                            </>
                                        )}
                                        
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* ✅ Add User Modal */}
            <Modal open={open} onClose={() => setOpen(false)} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
                <Fade in={open}>
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: {xs: '90%', md:600}, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                        <Typography variant="h6" sx={{ mb: 2 }}>Add User</Typography>
                        <TextField fullWidth label="Name" value={newUser.name} onChange={(e) => handleChange('name', e.target.value)} sx={{ mb: 2 }} />
                        <TextField fullWidth label="Email" value={newUser.email} onChange={(e) => handleChange('email', e.target.value)} sx={{ mb: 2 }} />
                        <TextField fullWidth label="Password" type="password" value={newUser.password} onChange={(e) => handleChange('password', e.target.value)} sx={{ mb: 2 }} />
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel>Role</InputLabel>
                            <Select value={newUser.role} onChange={(e) => handleChange('role', e.target.value)}>
                                <MenuItem value="admin">Admin</MenuItem>
                                <MenuItem value="employee">Employee</MenuItem>
                            </Select>
                        </FormControl>
                        <Button variant="contained" onClick={addUser}>Save</Button>
                    </Box>
                </Fade>
            </Modal>

            {/* ✅ Edit User Modal */}
            <Modal open={editOpen} onClose={() => setEditOpen(false)} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
                <Fade in={editOpen}>
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: {xs: '90%', md:600}, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                        <Typography variant="h6" sx={{ mb: 2 }}>Edit User</Typography>
                        <TextField fullWidth label="Name" value={newUser.name} onChange={(e) => handleChange('name', e.target.value)} sx={{ mb: 2 }} />
                        <TextField fullWidth label="Email" value={newUser.email} onChange={(e) => handleChange('email', e.target.value)} sx={{ mb: 2 }} />
                        <TextField fullWidth label="New Password (Leave blank to keep current password)" type="password" value={newUser.password} onChange={(e) => handleChange('password', e.target.value)} sx={{ mb: 2 }} />
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel>Role</InputLabel>
                            <Select value={newUser.role} onChange={(e) => handleChange('role', e.target.value)}>
                                <MenuItem value="admin">Admin</MenuItem>
                                <MenuItem value="employee">Employee</MenuItem>
                            </Select>
                        </FormControl>
                        <Button variant="contained" onClick={updateUser}>Save Changes</Button>
                    </Box>
                </Fade>
            </Modal>
        </Box>
    );
};

export default AccountSettings;
